import axios from "axios"
import http from "http"
import _ from "lodash"

const users = []

export const getUsers = async forceRefresh => {
  return new Promise(function(resolve, reject) {
    if (users.length === 0 || forceRefresh) {
      axios.get("/users").then(
        response => {
          users.length = 0
          Array.prototype.push.apply(users, response.data)
          resolve(users)
        },
        error => {
          reject(error)
          console.log("getUsers error:", error)
        }
      )
    } else {
      resolve(users)
    }
  })
}

export const updateUsers = async (id, users) => {
  return new Promise(function(resolve, reject) {
    axios.put("/users/" + id, users).then(
      response => {
        console.log("Users " + id + " updated successfully", response)
        // response.data is the updated story object
        resolve(response.data)
      },
      error => {
        console.log("updateUsers error:", error)
        reject(error)
      }
    )
  })
}

// export const UpdateUsers = async users => {
//   return new Promise(function(resolve, reject) {
//     axios.post("/users", users).then(
//       response => {
//         console.log("User created successfully", response)

//         var updatedUsers = response.data
//         updatedUsers.canEdit = true
//         updatedUsers.canDelete = true

//         resolve(util.fixUrls(updatedUsers))
//       },
//       error => {
//         console.log("createUsers error:", error)
//         reject(error)
//       }
//     )
//   })
// }

export const createUsers = async users => {
  return new Promise(function(resolve, reject) {
    axios.post("/users", users).then(
      response => {
        console.log("User created successfully", response)

        var updatedUsers = response.data
        updatedUsers.canEdit = true
        updatedUsers.canDelete = true

        resolve(util.fixUrls(updatedUsers))
      },
      error => {
        console.log("createUsers error:", error)
        reject(error)
      }
    )
  })
}

export const getUser = async id => {
  return new Promise(function(resolve, reject) {
    axios.get("/users/" + id).then(
      response => {
        console.log("users queried successfully", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("getUsers ERROR: organizations not found", error)
        reject(error)
      }
    )
  })
}

export default {
  getUsers,
  createUsers,
  updateUsers,
}
